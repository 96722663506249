<template>
    <div>
        <v-btn
            :loading="loading.packingList"
            small
            class="ml-2 lighten-2"
            v-if="updateMode && !listMode"
            @click.stop="printPackingList()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printPackingList()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.packingList"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.pdf') }}</span>
        </v-list-item>
        <PrintPackingListBlob
            :packing-list-id="packingListId"
            :key="updatePackingListKey"
            @pdf-uploading="packingListUploading"
            @pdf-uploaded="packingListUploaded"
            @pdf-not-uploaded="packingListNotUploaded"
            v-if="hasPackingList"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";

const PrintPackingListBlob = () => import("Views/v1/pldos/PrintPackingListBlob");
export default {
    name: "PrintPackingListButton",
    props: ['packingListId','updateMode','listMode'],
    components: {PrintPackingListBlob},
    data() {
        return {
            currentPackingListPdf: null,
            hasPackingList: false,
            loading: {
                packingList: false,
            },
            updatePackingListKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/packinglists/" + this.packingListId, {
                        params: {
                            fields: [
                                'pldos.location AS pldos__location'
                            ]
                        }
                    })
                    .then(response => {
                        const packingList = response.data.data[0]
                        if(packingList){
                            this.currentPackingListPdf = packingList.Pldo.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        packingListNotUploaded() {
            this.loading.packingList = false
            this.hasPackingList = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        packingListUploaded(url) {
            this.currentPackingListPdf = url
            this.loading.packingList = false
            this.viewFile(this.currentPackingListPdf)
            this.hasPackingList = false
        },
        packingListUploading() {
            this.loading.packingList = true
        },
        printPackingList() {
            this.loading.packingList = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentPackingListPdf != null){
                        this.viewFile(this.currentPackingListPdf)
                            .then(() => this.loading.packingList = false)
                            .catch(() => this.loading.packingList = false)
                    } else {
                        this.hasPackingList = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.packingList = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    }
}
</script>

<style scoped>

</style>